body {
  margin: 20;
  font-family: "Urbanist";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Urbanist";
  src: local("Urbanist"),
    url("./assets/fonts/Urbanist-Black.ttf") format("truetype");
  font-weight: "normal";
}

@font-face {
  font-family: "Urbanist-Medium";
  src: local("Urbanist-Medium"),
    url("./assets/fonts/Urbanist-Medium.ttf") format("truetype");
  font-weight: "normal";
}

@font-face {
  font-family: "Urbanist-SemiBold";
  src: local("Urbanist-SemiBold"),
    url("./assets/fonts/Urbanist-SemiBold.ttf") format("truetype");
  font-weight: "normal";
}

@font-face {
  font-family: "Urbanist-Bold";
  src: local("Urbanist-Bold"),
    url("./assets/fonts/Urbanist-Bold.ttf") format("truetype");
  font-weight: "normal";
}

@font-face {
  font-family: "Urbanist-ExtraBold";
  src: local("Urbanist-ExtraBold"),
    url("./assets/fonts/Urbanist-ExtraBold.ttf") format("truetype");
  font-weight: "normal";
}
